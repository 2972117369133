<template>
  <div class="flex justify-center sm:container lg:pt-16">
    <div
      class="border flex w-full max-w-2xl rounded-sm border-neutral-50 bg-white p-5 md:p-8"
    >
      <IconLockClosed
        class="mr-4 h-10 w-10 flex-shrink-0 text-neutral-100 md:h-14 md:w-14"
      />
      <div>
        <h1 class="text-lg font-medium text-neutral-900">
          {{ $t("pages.brandPackagesNotAuthorized.title") }}
        </h1>
        <p class="mb-3 text-sm text-neutral-600">
          {{ $t("pages.brandPackagesNotAuthorized.text") }}
        </p>
        <div class="flex flex-wrap">
          <BaseButton
            size="medium"
            variant="outline"
            :text="$t(`pages.brandPackagesNotAuthorized.buttonPackages`)"
            class="mt-2 mr-2"
            @buttonClick="redirectToPackages"
          />
          <BaseButton
            size="medium"
            variant="outline"
            :text="$t(`pages.brandPackagesNotAuthorized.buttonHome`)"
            class="mt-2 mr-2"
            @buttonClick="redirectToHome"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    redirectToHome() {
      this.$router.push({ name: "home" });
    },
    redirectToPackages() {
      this.$router.push({ name: "brand-marketing-packages" });
    },
  },
};
</script>
